

























import { Component, Prop, Vue } from 'vue-property-decorator';
import { AgGridVue } from 'ag-grid-vue';
import { Coupon } from '@shared/models/coupon';
import { Routes } from '@rs-911/router/routes';

@Component({
  components: {
    AgGridVue
  }
})
export default class SecondPartnerSubscriptions extends Vue {
  rowData = null;
  routes = Routes;
  frCoupon!: Coupon;
  async created() {
    this.frCoupon = this.$store.state.coupon.frCoupon;
    if(!this.frCoupon) {
      await this.$store.dispatch('coupon/getAllSecondPartner');
      this.frCoupon = this.$store.state.coupon.frCoupon; 
    }

    const result = await this.$store.dispatch('coupon/getAllSecondPartnerCouponsById', this.frCoupon?.id);
    this.rowData = result.data.subscriptions.map((item: any) => {
      return { ...item, installDate: new Date(item.installDate).toLocaleString() };
    });
  }

  defaultColDefs = {
    resizable: true,
    flex: 1,
    sortable: true,
    filter: true,
    cellStyle: {textAlign: 'left'}
  };

  columnDefs= [
    { headerName: 'Full Name', field: 'user.fullName' },
    { headerName: 'Email', field: 'user.email'},
    { headerName: 'Install Date', field: 'installDate' },
  ];
}
